<template>
  <tr v-bind:class="{ 'item-blue': item.id }">
    <td>
      <v-btn icon @click="rem">
        <v-icon>remove</v-icon>
      </v-btn>
    </td>
    <td>{{ index + 1 }}</td>
    <td>
      <v-autocomplete
        v-model="item.insuredId"
        :items="insuredItems"
        item-value="id"
        item-text="label"
        :rules="[rules.required, cardPeriodRule]"
        required
        clearable
        @update:search-input="debounceSearchInsured"
      >
        <template v-slot:item="{item}">
          <InsuredAutocompleteItem :insured="item"></InsuredAutocompleteItem>
        </template>
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.doctorId"
        :items="ctx.src.doctors.items"
        item-text="fullName"
        item-value="id"
        clearable
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field
        v-model="item.postingDate"
        type="date"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.visitDate"
        type="date"
        :rules="[requiredRules]"
        required
      ></v-text-field>
    </td>
    <td>
      <v-autocomplete
        v-model="item.diagnosisId"
        :items="ctx.src.diagnosis.items"
        item-text="name"
        item-value="id"
        :rules="requiredRules"
        required
        clearable
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.serviceId"
        :items="serviceItems"
        item-text="name"
        item-value="id"
        clearable
        :rules="requiredRules"
        required
        @change="getPrice"
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.serviceCategoryId"
        :items="ctx.src.categories.items"
        item-text="name"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field
        v-model="item.quantity"
        type="number"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.amount"
        type="number"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.discountPercent" type="number"></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.discountedAmount"
        type="number"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.checkNumber"
        readonly
        v-on:keyup.tab="addNew"
      ></v-text-field>
    </td>
    <v-dialog v-model="dialogReserve" max-width="800px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">Найден лимит или резерв</span>
        </v-card-title>

        <v-card-text>
          <v-form v-if="reserve" ref="form" style="overflow: auto">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="reserve.doctorId"
                    :items="ctx.src.doctors.items"
                    :label="'Доктор'"
                    item-text="fullName"
                    item-value="id"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="reserve.diagnosisId"
                    :items="ctx.src.diagnosis.items"
                    :label="'Диагноз'"
                    item-text="name"
                    item-value="id"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="reserve.serviceId"
                    :items="serviceItems"
                    :label="'Услуга'"
                    item-text="name"
                    item-value="id"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="reserve.serviceCategoryId"
                    :items="ctx.src.categories.items"
                    :label="'Категория услуги'"
                    item-text="name"
                    item-value="id"
                    readonly
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="reserve.quantity"
                    label="Количество"
                    type="number"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="reserve.amount"
                    label="Сумма"
                    type="number"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="reserve.discountPercent"
                    label="Скидка, %"
                    type="number"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="reserve.discountedAmount"
                    label="Сумма со скидкой"
                    type="number"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="reserve.discountedAmount"
                    label="Сумма со скидкой"
                    type="number"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-alert color="blue" type="info"
          >Хотите применить следующий лимит / резерв к записи?
          </v-alert
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogReserve = false">
            Отмена
          </v-btn>
          <v-btn color="primary" text @click="addReserve">
            Применить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProcessing" max-width="390px" persistent>
      <v-card>
        <v-card-text
          style="padding-top: 24px; display: flex; flex-direction: column; align-items: center"
        >
          <v-progress-circular
            :width="3"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <span>Обработка... Пожалуйста подождите...</span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </tr>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import round2hunds from "../../utils/round2hunds";
import {getServicesByOrganization} from "../../ctx/src/servicesByOrganization";
import moment from "moment";
import InsuredAutocompleteItem from "@/components/invoices/InsuredAutocompleteItem";
import insuredCardStatusEnum from "@/utils/staticData/insuredCardStatusEnum";
import {debounce} from "lodash";


const vmsPattern = '^CVMC.*';


export default {
  components: {InsuredAutocompleteItem},
  props: {
    item: Object,
    index: Number,
    items: Array
  },
  data: () => ({
    ctx,
    requiredRules: [v => !!v || "Обязательное поле"],
    reserves: [],
    dialogReserve: false,
    reserve: undefined,
    dialogProcessing: false,
    dialogLimit: false,
    serviceItems: [],
    rules: {
      required: v => !!v || "Обязательное поле",

    },
    insuredList: [],
    currentInput: null

  }),
  watch: {
    "item.serviceId": function (val) {

      if (!this.item.insuredId) return;

      this.getReserves(this.item.insuredId).then(() => {
        if (val) this.serviceIdChanged(val);
      });
      this.getPrice();
    },
    "item.amount": function (val) {
      if (!val) return (this.item.discountedAmount = 0);
      if (!this.item.discountPercent || this.item.discountPercent === 0)
        return (this.item.discountedAmount = val);
      else
        return (this.item.discountedAmount = round2hunds(
          val - (val * this.item.discountPercent) / 100
        ));
    },
    "item.discountPercent": function (val) {
      if (!val) return (this.item.discountedAmount = this.item.amount);


      else
        return (this.item.discountedAmount = round2hunds(
          this.item.amount - (this.item.amount * val) / 100
        ));
    },
    "item.insuredId": function (val) {
      if (!val) return;
      this.getServices().then(result => {
        this.serviceItems = result;
      })
      this.getReserves(val);

      this.$emit('validate');


    },
    "item.visitDate": function () {
      this.$emit('validate');
    }
  },
  methods: {

    async cardPeriodRule() {
      if (!this.item.insuredId) return true;
      const insured = await fetch.get(`/api/insurance/insured/get?id=${this.item.insuredId}`);


      return (moment(this.item.visitDate).isSameOrBefore(moment(insured.endDate)) && moment(this.item.visitDate).isSameOrAfter(moment(insured.startDate))) || "Услуга не входит в период действия карты застрахованного"
    },

    async doesInsuredAlreadyHaveInvoiceService() {
      if (!(this.item.insuredId && this.item.serviceId && this.item.doctorId && this.item.organizationId && this.item.visitDate && this.organizationIsSosmaRegions)) return false;
      return this.isThereSuchInvoiceServiceInLocalTable() || await this.isThereInvoiceServiceOnBackEnd();


    },

    isThereSuchInvoiceServiceInLocalTable() {
      if (this.items.length < 2) return false
      const itemIndex = this.items.indexOf(this.item)
      const searchableItems = this.items.slice(0, itemIndex)
        .concat(this.items.slice(itemIndex + 1, this.items.length))
      return !!searchableItems.find(i =>
        this.item.insuredId === i.insuredId &&
        this.item.doctorId === i.doctorId &&
        this.item.serviceId === i.serviceId &&
        moment(this.item.visitDate).isSame(moment(i.visitDate))
      );
    },

    async isThereInvoiceServiceOnBackEnd() {
      return await fetch.post('/api/accounting/invoices/invoice-service-exists', {
        "serviceId": this.item.serviceId,
        "organizationId": this.item.organizationId,
        "insuredId": this.item.insuredId,
        "doctorId": this.item.doctorId,
        "visitDate": this.item.visitDate
      });
    },

    async getPrice() {
      if (!this.item.serviceId) return;
      if (!this.item.organizationId) return;
      if (!this.item.insuredId) return;

      const insured = await fetch.get(`/api/insurance/insured/get?id=${this.item.insuredId}`);
      if (!insured) return;
      const contract = await fetch.get(`/api/insurance/contracts/${insured.contractId}`)
      if (!contract) return;

      const organizationId =
        this.item.organizationId == 10892939
          ? 10190272
          : this.item.organizationId;
      // 10892939 - это Алматы SOSMA-Самал, 10190272 - это Алматы SOSMA

      const price = await fetch.get(
        "/api/accounting/price-list/get-price?organizationId=" +
        organizationId +
        "&serviceId=" +
        this.item.serviceId +
        "&dateOn=" +
        this.item.visitDate
      );


      if (!price) return;
      this.item.amount = price;
    },
    serviceIdChanged(val) {
      if (this.reserves.length > 0) {
        this.reserve = this.reserves.find(r => r.serviceId === val);
        if (this.reserve) {
          let exist = this.$parent.$parent.$parent.items.find(
            i => i.id === this.reserve.id
          );
          if (!exist) this.dialogReserve = true;
        }
      }
      if (!val) return (this.item.serviceCategoryId = undefined);
      let service = ctx.src.services.get(val);
      if (service) this.item.serviceCategoryId = service.categoryId;
      else this.item.serviceCategoryId = undefined;
    },

    async getReserves(insuredId) {


      if (!this.item || this.mount) return;
      return new Promise(resolve => {
        fetch
          .get(
            "/api/accounting/invoices/get-reserves-by-insured?insuredId=" +
            insuredId
          )
          .then(res => {
            this.reserves = res;
            resolve();
          });
      });
    },

    async checkLimit() {
      await this.$parent.$parent.$parent.checkLimit(this.item);
    },
    async addNew() {
      await this.checkLimit();
      await this.getReserves(this.item.insuredId);

      if (this.index + 1 === this.$parent.$parent.$parent.items.length)
        this.$parent.$parent.$parent.addItem();

    },


    async rem() {


      await fetch.post("/api/accounting/invoices/delete-invoice-temp/" + this.item.entryUuid);
      this.$parent.$parent.$parent.items.splice(this.index, 1);
    },
    addReserve() {
      this.item.id = this.reserve.id;
      this.item.amount = this.reserve.amount;
      this.item.quantity = this.reserve.quantity;
      this.item.checkNumber = this.reserve.checkNumber;
      this.dialogReserve = false;
    },

    async getServices() {
      const insured = fetch.get(`/api/insurance/insured/get?id=${this.item.insuredId}`);
      return await getServicesByOrganization(
        this.item.organizationId,
        this.serviceItems,
        undefined,
        insured.contractId,
        this.item.insuredId
      );
    },

    async searchInsured(input) {
      if(!input) return;
      if(typeof (input) !== 'string') input = input.text
      input = this.parseCardNoFromInput(input)
      if (!input || this.currentInput !== null && (this.currentInput === input || this.currentInput.length < 4) || input.length < 4) return;
      this.loading = true;
      const result = await fetch.get(`/api/insurance/insured/search-by-predicate?predicate=${input}`)
      this.insuredList = []
      result.forEach(insured => {
        this.insuredList.push({
          id: insured.id,
          text: insured.cardNo,
          fullName: insured.fullName,
          label: insured.cardNo + " - " + insured.fullName,
          cardStatus: this.getCardStatusByInsuredData(insured)

        })
      })
      this.currentInput = input
      this.loading = false;

    },

    parseCardNoFromInput(input) {
      return input.split(" - ")[0]
    },


    getCardStatusByInsuredData(options) {
      if (moment().isBefore(moment(options.startDate))) return insuredCardStatusEnum.NOT_STARTED;
      else if (moment().isAfter(moment(options.endDate))) return insuredCardStatusEnum.EXPIRED;
      else if (options.cardNo.match(vmsPattern)) return insuredCardStatusEnum.VMS
      return insuredCardStatusEnum.RELEVANT;
    },


    debounceSearchInsured(input) {

      debounce(async () => {
        await this.searchInsured(input)
      }, 1000)()

    },
    async fetchInsured() {
      const insured = await fetch.get(`/api/insurance/insured/get?id=${this.item.insuredId}`)
      this.insuredList.push({
        id: insured.id,
        text: insured.cardNo,
        fullName: insured.fullName,
        label: insured.cardNo + " - " + insured.fullName,
        cardStatus: this.getCardStatusByInsuredData(insured)

      })
    }

  },



  computed: {
    insuredItems: function () {
      return this.insuredList.filter(item => item.cardStatus !== insuredCardStatusEnum.NOT_STARTED).sort((item1, item2) => {
        if (item1.cardStatus === insuredCardStatusEnum.RELEVANT && item2.cardStatus === insuredCardStatusEnum.EXPIRED) return -1;
        else if (item1.cardStatus === insuredCardStatusEnum.EXPIRED && item2.cardStatus === insuredCardStatusEnum.RELEVANT) return 1;
        return 0;

      });
    }
    ,
    organizationIsSosmaRegions() {
      return this.item.organizationId === 10499899
    }
  }
  ,


  async mounted() {
    if (this.item.insuredId) {
      await this.fetchInsured()
      if (this.item.discountedAmount) await this.checkLimit();
      this.getServices().then(result => this.serviceItems = result);

    }


  }
  ,
  async updated() {

    const doesInvoiceServiceExist = await this.doesInsuredAlreadyHaveInvoiceService()
    if (doesInvoiceServiceExist) this.$emit("invoiceServiceUniquenessParametersChange", doesInvoiceServiceExist)
  }
}
;
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}

.card-expired {
  color: red;
}

.card-not-started {
  color: blue;
}

</style>
