import storage from "../utils/storage";
import jwtParser from "../utils/jwtParser";

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { layout: "empty" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/profile",
    name: "profile",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Profile.vue")
  },
  {
    path: "/limit-services-by-organization",
    name: "limit-services",
    meta: {
      layout: "main",
      auth: true,
      group: "directory",
      label: "Ограничение услуг"
    },
    component: () => import("@/views/LimitServices.vue")
  },
  {
    path: "/companies",
    name: "companies",
    meta: {
      layout: "main",
      auth: true,
      group: "insurance",
      label: "Компании",
      access: "COMPANIES"
    },
    component: () => import("@/views/Companies.vue")
  },
  {
    path: "/insured",
    name: "insured",
    meta: {
      layout: "main",
      auth: true,
      group: "insurance",
      label: "Застрахованные",
      access: "INSURED"
    },
    component: () => import("@/views/Insured.vue")
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: {
      layout: "main",
      auth: true,
      group: "insurance",
      label: "Счета",
      access: "INVOICES"
    },
    component: () => import("@/views/Invoices.vue")
  },
  {
    path: "/prices",
    name: "prices",
    meta: {
      layout: "main",
      auth: true,
      group: "insurance",
      label: "Прейскурант",
      access: "PRICES"
    },
    component: () => import("@/views/Prices.vue")
  },
  {
    path: "/lists",
    name: "lists",
    meta: {
      layout: "main",
      auth: true,
      group: "directory",
      label: "Списки",
      access: "LISTS"
    },
    component: () => import("@/views/Lists.vue")
  },
  {
    path: "/categories",
    name: "categories",
    meta: {
      layout: "main",
      auth: true,
      group: "directory",
      label: "Категории услуг",
      access: "CATEGORIES"
    },
    component: () => import("@/views/Categories.vue")
  },
  {
    path: "/diagnoses",
    name: "diagnoses",
    meta: {
      layout: "main",
      auth: true,
      group: "directory",
      label: "Диагнозы",
      access: "DIAGNOSES"
    },
    component: () => import("@/views/Diagnoses.vue")
  },
  {
    path: "/familydoctors",
    name: "familyDoctors",
    meta: {
      layout: "main",
      auth: true,
      group: "directory",
      label: "Врачи",
      access: "FAMILY_DOCTORS"
    },
    component: () => import("@/views/FamilyDoctors.vue")
  },
  // {
  //   path: "/clinicdoctors",
  //   name: "clinicDoctors",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "directory",
  //     label: "Врачи клиник",
  //     access: "CLINIC_DOCTORS"
  //   },
  //   component: () => import("@/views/ClinicDoctors.vue")
  // },
  {
    path: "/reports/bycompany",
    name: "byCompany",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по компании",
      access: "REPORT_BY_COMPANY"
    },
    component: () => import("@/views/reports/ByCompany.vue")
  },
  // {
  //   path: "/reports/byclinic",
  //   name: "byClinic",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "reports",
  //     label: "Отчет по медицинскому учреждению",
  //     access: "REPORT_BY_CLINIC"
  //   },
  //   component: () => import("@/views/reports/ByClinic.vue")
  // },
  {
    path: "/reports/byinsured",
    name: "byInsured",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по застрахованному",
      access: "REPORT_BY_INSURED"
    },
    component: () => import("@/views/reports/ByInsured.vue")
  },
  {
    path: "/reports/balances",
    name: "Balances",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по перелимитам застр-х",
      access: "REPORT_BY_BALANCES"
    },
    component: () => import("@/views/reports/Balances.vue")
  },
  // {
  //   path: "/reports/insuredbalances",
  //   name: "insuredBalances",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "reports",
  //     label: "Отчет по перелимитам застр-х",
  //     access: "REPORT_INSURED_BALANCES"
  //   },
  //   component: () => import("@/views/reports/InsuredBalances.vue")
  // },
  // {
  //   path: "/reports/byproducts",
  //   name: "byProducts",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "reports",
  //     label: "Отчет по продуктам",
  //     access: "REPORT_BY_PRODUCTS"
  //   },
  //   component: () => import("@/views/reports/ByProducts.vue")
  // },
  // {
  //   path: "/reports/usersactions",
  //   name: "usersActions",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "reports",
  //     label: "Изменение данных пользователями",
  //     access: "REPORT_USERS_ACTIONS"
  //   },
  //   component: () => import("@/views/reports/UsersActions.vue")
  // },
  // {
  //   path: "/reports/byappointments",
  //   name: "byAppointments",
  //   meta: {
  //     layout: "main",
  //     auth: true,
  //     group: "reports",
  //     label: "Отчет по посещаемости",
  //     access: "REPORT_BY_APPOINTMENTS"
  //   },
  //   component: () => import("@/views/reports/ByAppointments.vue")
  // },
  {
    path: "/reports/detachedtotal",
    name: "detachedTotal",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по количеству открепленных",
      access: "REPORT_BY_DETACHED_TOTAL_CLIENT"
    },
    component: () => import("@/views/reports/DetachedTotal.vue")
  },
  {
    path: "/reports/combinedtotal",
    name: "combinedTotal",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по количеству застрахованных",
      access: "REPORT_BY_COMBINED_TOTAL_CLIENT"
    },
    component: () => import("@/views/reports/CombinedTotal.vue")
  },
  {
    path: "/reports/underwriting",
    name: "underwritingReport",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по андеррайтингу",
      access: "UNDERWRITING_REPORT"
    },
    component: () => import("@/views/reports/Underwriting.vue")
  },

  {
    path: "/reports/appendix-4",
    name: "appendix4Report",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Приложение 4",
     access: "APPENDIX_4"
    },
    component: () => import("@/views/reports/Appendix4.vue")
  },

  {
    path: "/reports/by-employee",
    name: "byEmployeeReport",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по пользователям",
      access: "REPORT_BY_EMPLOYEE"
    },
    component: () => import("@/views/reports/ByEmployee.vue")
  },

  {
    path: "/reports/by-invoice",
    name: "reportByInvoice",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по внесённым счетам",
      access: "REPORT_BY_INVOICE"

    },
    component: () => import("@/views/reports/ByInvoice.vue")
  },

  {
    path: "/reports/oktell",
    name: "oktellReport",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по номерам Oktell",
      access: "OKTELL_REPORT_BY_NUMBERS"
    },
    component: () => import("@/views/reports/OktellNumberReport.vue")
  },

  {
    path: "/reports/by-visit",
    name: "reportByVisit",
    meta: {
      layout: "main",
      auth: true,
      group: "reports",
      label: "Отчет по визитам",
      access: "REPORT_BY_VISITS"
    },
    component: () => import("@/views/reports/ByVisit.vue")
  },

  {
    path: "/settings/users",
    name: "users",
    meta: {
      layout: "main",
      auth: true,
      group: "settings",
      label: "Пользователи",
      access: "SETTING_USERS"
    },
    component: () => import("@/views/Users.vue")
  }
];

export default {
  accessible: function() {
    let arr = [];
    const jwt = storage.getJwt();

    // for oktell app
    const urlParams = new URLSearchParams(window.location.search);
    const operatorID = urlParams.get('operatorID');
    const cardNo = urlParams.get('cardNo');


    if (jwt) {
      const data = jwtParser.parse(jwt);
      routes.forEach(r => {
        if (!r.meta.access) {
          arr.push(r);
        } else {
          const acc = data.access.find(function(element) {
            return element.access === r.meta.access;
          });
          if (acc?.rights.includes("r")) arr.push(r);
        }
      });
      if (arr.length > 0) {
        const def = arr.filter(element => {
          return element.meta && element.meta.access;
        });
        if (def.length > 0)
          arr.push({
            path: "/",
            redirect: def[0].path,
            meta: {}
          });
      }
    } else {
      routes.forEach(r => {
        if (!r.meta.access) {
          arr.push(r);
        }
      });

      if(operatorID && cardNo) {
        arr.push({
          path: "/",
          redirect: "/insured",
          meta: {}
        });
      } else {
        arr.push({
          path: "/",
          redirect: "/login",
          meta: {}
        });
      }      
    }
    return arr;
  },
  get: function() {
    return routes;
  }
};
