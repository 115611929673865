<template>
  <v-autocomplete
    ref="select"
    v-model="insuredId"

    :items="insured"
    :rules="[requiredRules]"
    required
    item-value="id"
    item-text="label"
    clearable
    @update:search-input="getInsuredByCardNo"
    @change="emitSelect"
  >
  </v-autocomplete>
</template>

<script>
import fetch from "../../utils/fetch";



export default {
  name: "InsuredSelect",
  props: {
    props: Object
  },
  data() {
    return {
      requiredRules: v => !!v || "Обязательное поле",
      insured: [],
      loading: false,
      page: 1,
      totalPages: 1,
      insuredId: null,
      currentInput: null
    }
  },
  methods: {
    async getInsured() {
      this.insuredId = this.props.id
      const result = await fetch.get(`/api/insurance/insured/get?id=${this.insuredId}`)
      this.insured.push({
        id: result.id,
        text: result.cardNo,
        label: result.label
      })

    },

    async getInsuredByCardNo(input) {
      if(!input) return;
      if(typeof(input) !== 'string') input = input.text
      if(this.currentInput === input || input.length < 4) return;
      this.loading = true;
      const cardNoParam = this.parseCardNoFromInput(input)
      const result = await fetch.get(`/api/insurance/insured/get-by-card-no-part?cardNoPart=${cardNoParam}`)
      this.insured = []
      result.forEach(insured => {
        this.insured.push({
          id: insured.id,
          text: insured.cardNo,
          label: insured.label
        })
      })
      this.loading = false
      this.currentInput = input
    },

    parseCardNoFromInput(input) {
      return input.split(" - ")[0]
    },


    initialize() {
      if(this.props.id !== null && this.props.id !== undefined) this.getInsured()
    },

    emitSelect() {
      console.log('EMIT DEBUG', this.insured.find(insured => insured.id === this.insuredId))
      this.$emit('insuredSelect', this.insured.find(insured => insured.id === this.insuredId))
    },







  },



  mounted() {
    this.initialize()
  },

}
</script>


