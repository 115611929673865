<template>
  <div class="view-wrapper view-wrapper-contracts">
    <div class="view fsh-3">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="searchText"
          hide-details
          append-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
        <v-btn style="margin-left: 5vw" @click="limitServices">
          Ограничить
        </v-btn>
        <v-btn style="margin-left: 5vw" @click="cancelServiceLimitation">
          Отменить ограничение
        </v-btn>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>

        <v-spacer />
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="false"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        :item-class="itemColor"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";

import vm2js from "../utils/vm2js";
import ctx from "@/ctx";

import rightFilter from "../filters/right.filter";

export default {
  data: () => ({
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    selected: [],
    loading: false,
    headers: [
      {
        text: "Название мед. учреждения",
        value: "fullName"
      }
    ],
    items: [],
    item: undefined,
    searchText: undefined,
    contract: undefined,
    limited: []
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = val[0];
    }
  },
  methods: {
    showLogs() {
      console.log("test");
    },

    search() {
      this.items = [];
      this.selected = [];
      this.currentPage = 0;
      const params = {
        page: this.currentPage,
        predicate: this.searchText
      };
      this.fetchItems(params);
    },
    add() {
      this.close();
    },
    close() {
      this.selected = [];
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems(params) {
      this.loading = true;
      let res;
      if (!params.predicate)
        res = await fetch.get("/api/org/get-list?page=" + params.page);
      else
        res = await fetch.get(
          "/api/org/search?predicate=" +
            params.predicate +
            "&page=" +
            params.page
        );
      if (res.first) {
        this.totalPages = res.totalPages;
        this.items = [];
      }
      res.content.forEach(r => {
        this.items.push(r);
      });
      this.loading = false;
    },
    async limitServices() {
      this.selected.forEach(async function(selectedItem) {
        await ctx.src.org.limitOrganizationServices(selectedItem.id);
        this.limited.push(selectedItem.id);
      });
      document.location.reload();
    },
    async cancelServiceLimitation() {
      this.selected.forEach(async function(selectedItem) {
        await ctx.src.org.cancelServiceLimitation(selectedItem.id);
        this.limited = this.limited.filter(l => l.id != this.selected.id);
      });
      document.location.reload();
    },
    async getLimited() {
      const res = await ctx.src.org.getLimited();
      console.log(res);
      return res;
    },
    itemColor(item) {
      if (this.limited.map(l => l.organizationId).includes(item.id)) {
        return "item-red";
      }
    }
  },
  async mounted() {
    this.limited = await this.getLimited();

    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  },

  components: {}
};
</script>
