import { render, staticRenderFns } from "./Underwriting.vue?vue&type=template&id=7858c264&scoped=true&"
import script from "./Underwriting.vue?vue&type=script&lang=js&"
export * from "./Underwriting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7858c264",
  null
  
)

export default component.exports