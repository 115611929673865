<template>
  <v-autocomplete
    ref="select"
    v-model="contractId"

    :items="contracts"
    :label="'Договор страхования'"
    :rules="[requiredRules]"
    required
    :readonly="props.byContract"
    item-value="id"
    item-text="text"
    clearable
    @update:search-input="getContractByCardNo"
    @change="emitSelect"
  >
  </v-autocomplete>
</template>

<script>
import fetch from "../../utils/fetch";

export default {
  name: "ContractsSelect",
  props: {
      props: Object
  },
  data() {
    return {
      requiredRules: v => !!v || "Обязательное поле",
      contracts: [],
      loading: false,
      page: 1,
      totalPages: 1,
      contractId: null,
      currentInput: null
    }
  },
  methods: {
    async getContract() {
      this.contractId = this.props.id
      const result = await fetch.get(`/api/insurance/contracts/${this.contractId}`)
      this.contracts.push({
        id: result.id,
        text: result.number
      })

    },

    async getContractByCardNo(input) {
      if(!input || this.currentInput === input) return;
      this.loading = true;
      const result = await fetch.get(`/api/insurance/contracts/get-by-number?number=${input}`)
      this.contracts = []
      result.forEach(contract => {
        this.contracts.push({
          id: contract.id,
          text: contract.number
        })
      })
      this.loading = false
      this.currentInput = input
    },


    initialize() {
      if(this.props.id !== null && this.props.id !== undefined) this.getContract()
    },

    emitSelect() {
      this.$emit('contractSelected', this.contracts.find(contract => contract.id === this.contractId))
    }





  },



  mounted() {
    this.initialize()
  },

}
</script>


