<template>
  <div class="view-wrapper">
    <div class="view">
      <v-card>
        <v-card-title style="padding: 4px 16px">
          <h6>Фильтр</h6>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.from"
                  label="С"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.to"
                  label="По"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.underwriterId"
                  :items="ctx.src.org.byType('1')"
                  :label="'Страховая'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.organizationId"
                  :items="ctx.src.org.byType('2')"
                  :label="'Клиентская'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
<!--              <v-col cols="12" md="2">-->
<!--                <v-text-field-->
<!--                  v-model="filter.personName"-->
<!--                  :label="'Застрахованный'"-->
<!--                  clearable-->
<!--                  @keydown.enter="fetchItems"-->
<!--                >-->
<!--                </v-text-field>-->
<!--              </v-col>-->
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="filter.mainInsured"
                  :label="'Только основн. застрх.'"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="fetchItems">
            Применить
          </v-btn>
          <v-btn small @click="resetFilter">
            Сбросить
          </v-btn>
          <v-spacer />
          <v-btn
            color="green"
            :loading="exporting"
            :disabled="items.length === 0"
            small
            class="white--text"
            @click="exp"
          >
            Экспорт
            <v-icon right dark>
              cloud_download
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
      >
        <!--        <template v-slot:item.startDateLocal="{ item }">-->
        <!--          <span>{{ item.startDateLocal | dateLocalFilter }}</span>-->
        <!--        </template>-->
        <template v-slot:item.postingDateLocal="{ item }">
          <span>{{ item.postingDateLocal | dateLocalFilter }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script scoped>
  import ctx from "@/ctx";
  import fetch from "@/utils/fetch";
  import js2vm from "@/utils/js2vm";
  import dateIsoFilter from "@/filters/dateIso.filter";
  import vm2js from "@/utils/vm2js";

  let Filter = function() {
    let self = this;

    let from = new Date();
    from.setDate(from.getDate() - 30);
    self.from = dateIsoFilter(from);
    self.to = dateIsoFilter(new Date());
    self.organizationId = undefined;
    self.underwriterId = undefined;
    self.personName = undefined;
    self.mainInsured = false;

  };

  let Item = function(d) {
    let self = this;
    d.underwriter = d.underwriterId;
    d.organization = d.organizationId;
    d.prog = d.name;
    d.card = d.cardNo;
    d.person = d.personName;
    d.usluganame = d.usluga;
    d.amoun = d.amount;
    d.amountSpen = d.amountSpent;
    d.quantit = d.quantity;
    d.quantitySpen = d.quantitySpent;
    d.remainingQuan = d.remainingQuantity;
    d.billQ = d.billquant;
    d.postingDateLocal = d.postingDate;
    d.med = d.medName;
    d.billN = d.billNumber;
    d.mai = d.main;
    d.remainingSu = d.remainingSum;

    self.organization2vm = function(val) {
      if (!val) return {};
      return ctx.src.org.first(val);
    };
    self.underwriter2vm = function(val) {
      if (!val) return {};
      return ctx.src.org.first(val);
    };
    self.organization2vm = function(val) {
      if (!val) return {};
      return ctx.src.org.first(val);
    };
    self.parent2vm = function(val) {
      if (!val) return {};
      return fetch.get(`/api/insurance/insured/get?id=${val}`);
    };

    self.program2vm = function(val) {
      if (!val) return {};
      return ctx.src.programs.get(val);
    };

    self.serviceCategory2vm = function(val) {
      if (!val) return {};
      return ctx.src.categories.get(val);
    };

    self.service2vm = function(val) {
      if (!val) return {};
      return ctx.src.services.get(val);
    };
    js2vm(self, d);
  };

  let Report = function(d) {
    let self = this;

    self.title = d.title;
    self.subtitle = d.subtitle;
    self.rows = [];

    d.rows.forEach(i => {
      self.rows.push(new Item(i));
    });
  };

  export default {
    data: () => ({
      ctx,
      selected: [],
      loading: false,
      headers: [
        {
          text: "Страховая ",
          align: "start",
          sortable: false,
          value: "insurer"
        },
        {
          text: "Клиент",
          sortable: false,
          value: "client"
        },
        {
          text: "Программа",
          sortable: false,
          value: "name"
        },
        {
          text: "№",
          sortable: false,
          value: "card"
        },
        {
          text: "ФИО",
          sortable: false,
          value: "person"
        },
        {
          text: "Категория",
          sortable: false,
          value: "usluganame"
        },
        {
          text: "Лимит",
          sortable: false,
          value: "amoun"
        },
        {
          text: "Израсх.",
          sortable: false,
          value: "amountSpen"
        },
        {
          text: "Сумма перелимита",
          sortable: false,
          value: "remainingSu"
        },
        { text: "К-во",
          sortable: false,
          value: "quantit",
        },
        {
          text: "Израсх. к-во",
          sortable: false,
          value: "quantitySpen",
        },
        {
          text: "К-во перелимита",
          sortable: false,
          value: "remainingQuan",
        },
        {
          text: "Счета.К-во",
          sortable: false,
          value: "billQ",
        },
        {
          text: "Дата внес.макс. ",
          sortable: false,
          value: "postingDateLocal",
        },
        {
          text: "Мед. учрежд",
          sortable: false,
          value: "med",
        },
        {
          text: "Счет №",
          sortable: false,
          value: "billN",
        },
        {
          text: "Осн застрах.",
          sortable: false,
          value: "mai",
        }
      ],
      report: {},
      filter: new Filter(),
      items: [],
      exporting: false
    }),
    // watch: {
    //   'filter.personName'(value){
    //     this.filter.personName = value.toLowerCase();
    //   }
    // },
    methods: {
      resetFilter() {
        this.filter = new Filter();
        console.log(this.filter);
      },
      async exp() {
        this.exporting = true
        const res = await fetch.post(
          "/api/report/by-insured-balances/export",
          vm2js(this.report)
        );
        if (res) {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(await res.blob);
          link.download = res.filename;
          link.click();
        }
        this.exporting = false
      },
      async fetchItems() {

        // this.filter.personName = this.filter.personName?.toUpperCase(); // uppercase

        this.loading = true;
        this.items = [];
        let res = await fetch.post(
          "/api/report/by-insured-balances/get",
          vm2js(this.filter)
        );
        if (res) {
          this.report = new Report(res);
          res.rows.forEach(r => {
            this.items.push(new Item(r));
          });
        }
        this.loading = false;
      },
    },
    // mounted(){
    //   this.filter.personName = this.filter.personName?.toLowerCase(); // uppercase
    // }
  };
</script>
