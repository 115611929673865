<template>
  <v-dialog v-if="dialogData" v-model=dialogShown max-width="800px" scrollable persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ dialogData.message }}</span>
      </v-card-title>

      <v-card-text>
        <v-alert

          color="red"
          type="info"
          style="margin-top: 16px"
        >!Внимание: {{ dialogData.message }}
        </v-alert>
        <v-form ref="form" style="overflow: auto">
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-autocomplete
                  v-model="item.insuredId"
                  :items="insuredList"
                  item-text="label"
                  item-value="id"
                  readonly
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="item.serviceCategoryId">
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.serviceCategoryId"
                  :items="ctx.src.categories.items"
                  :label="'Категория услуги'"
                  item-text="name"
                  item-value="id"
                  readonly
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="dialogData.excess"
                  :label="dialogData.label"
                  type="number"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="item.serviceId"
                  :items="ctx.src.services.items"
                  :label="'Услуга'"
                  item-text="name"
                  item-value="id"
                  readonly
                >
                </v-autocomplete>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-card-text>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :disabled="okButtonDisabled" @click="confirmDialog">
          Ok <sub>{{okButtonDisabledCountDown !== 0 ? okButtonDisabledCountDown : ""}}</sub>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ctx from "@/ctx";
  import fetch from "../../utils/fetch";
  import checkStatusEnum from "@/utils/staticData/checkStatusEnum";

  export default {
    props: {
      vm: Object
    },
    data: () => ({
      ctx,
      item: undefined,
      currentPromise: undefined,
      resolve: undefined,
      dialogData: undefined,
      checkState: undefined,
      checkStatusEnum: checkStatusEnum,
      insuredList: [],
      dialogShown: false,
      okButtonDisabled: false,
      okButtonDisabledCountDown: 10,
      countdownIntervalId: null
    }),
    methods: {
      setItem: function(item) {
        this.item = item;
        this.getInsured()
      },
      check: async function (insuredId, serviceCategoryId, discountedAmount = null, quantity = null, id = null) {
        const result = await fetch.post("/api/accounting/invoices/check-by-limit", {
          "serviceCategoryId": serviceCategoryId,
          "insuredId": insuredId,
          "discountedAmount": discountedAmount,
          "quantity": quantity,
          "id": id
        })

        if (!result) return;
        this.checkState = result.checkState;


        this.getDialogData(result);

        if (this.dialogData) {
          this.showDialog();
        }
      },
      showDialog() {
        this.okButtonDisabled = true;
        setTimeout(() => {
          this.okButtonDisabled = false
        }, 10000)
        this.okButtonDisabledCountDown = 10;
        this.countdownIntervalId = setInterval(() => {
          if(this.okButtonDisabledCountDown <= 1) clearInterval(this.countdownIntervalId)
          this.okButtonDisabledCountDown = this.okButtonDisabledCountDown - 1
        }, 1000)
        this.dialogShown = true;
      },
      confirmDialog() {
        this.dialogShown = false;
      },
      getDialogData(result) {
        if (result.checkState === checkStatusEnum.NORMAL) {
          this.dialogData = undefined;
          return;
        }
        let data = {};
        switch (result.checkState) {
          case checkStatusEnum.NOT_IN_LIMITS:
            data = {
              message: "Услуга отсутсвует в лимитах"
            }
            break;
          case checkStatusEnum.EXCESS_BY_AMOUNT:
            data = {
              message: "Превышение лимита по сумме",
              excess: result.excess,
              label: "Превышение, сумма"
            }
            break;
          case checkStatusEnum.ONLY_MAIN_INSURED:
            data = {
              message: "Услугу позволяется создать только у основного застрахованного"
            }
            break;
          case checkStatusEnum.EXCESS_BY_QUANTITY:
            data = {
              message: "Превышение лимита по количеству",
              excess: result.excess,
              label: "Превышение, количество"
            }
            break;
        }

        this.dialogData = data;

      },
      async getInsured() {
        const insured = await fetch.get(`/api/insurance/insured/get?id=${this.item.insuredId}`)
        this.insuredList.push({
          id: insured.id,
          label: insured.cardNo
        })
      }

    },



     mounted() {


      this.vm.limitCheck = this;





    }
  };
</script>
