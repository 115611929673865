<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container>
      <v-row>
        <v-col class="d-flex" cols="12" sm="12">
          <v-autocomplete
            v-model="vm.item.organizationId"
            :items="ctx.src.org.byType(['3', '4'])"
            :label="'Медицинское учреждение'"
            item-text="fullName"
            item-value="id"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.invoiceId"
            :items="items"
            :loading="loading"
            hide-no-data
            :label="'Счет'"
            item-text="number"
            item-value="id"
            clearable
          >
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.number"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <h5>
                          от {{ data.item.postingDate | dateLocalFilter }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.stateKey"
            :items="ctx.src.dict['INS_ACCOUNT_LINE_STATUS'].items"
            :label="'Статус'"
            item-text="value"
            item-value="key"
            required
            :rules="[rules.required]"
            clearable
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="vm.item.postingDate"
            label="Дата введения"
            type="date"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="vm.item.visitDate"
            label="Дата обращения"
            type="date"
            clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.doctorId"
            :items="ctx.src.doctors.items"
            :label="'Доктор'"
            item-text="fullName"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.diagnosisId"
            :items="ctx.src.diagnosis.items"
            :label="'Диагноз1'"
            item-text="name"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="vm.item.diagnosisExt" readonly label="Диагноз2">
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.serviceId"
            :items="serviceItems"
            :label="'Услуга'"
            item-text="name"
            item-value="id"
            clearable
            required
            :rules="[rules.required]"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.serviceCategoryId"
            :items="ctx.src.categories.items"
            :label="'Категория услуги'"
            item-text="name"
            item-value="id"
            readonly
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.item.quantity"
            label="Количество"
            type="number"
            required
            :rules="[rules.requiredIncludesZero, zeroQuantityRule]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.item.amount"
            label="Сумма"
            type="number"
            required
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.item.discountPercent"
            label="Скидка, %"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="vm.item.discountedAmount"
            label="Сумма со скидкой"
            type="number"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import round2hunds from "../../utils/round2hunds";
import {requiredRule} from "@/utils/staticData/commonRules";
import {zeroQuantityInvoiceServices} from "@/utils/staticData";

export default {
  props: {
    vm: Object,
    isReserve: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    ctx,
    rules: {
      required: requiredRule,
      requiredIncludesZero: v => (!!v || v === 0) || 'Обязательное поле'
    },
    valid: false,
    loading: false,
    items: [],
    serviceItems: []
  }),
  watch: {
    "vm.item": function () {
      this.$refs.form.validate();
    },
    "vm.item.serviceId": function (val) {
      if (!val) return (this.vm.item.serviceCategoryId = undefined);
      const service = ctx.src.services.get(val);
      if (service) this.vm.item.serviceCategoryId = service.categoryId;
        //
        // if (service){
        //   this.vm.item.serviceCategoryId = service.categoryId;
        //   //
        //   // ctx.src.services.get(val).name = service.name + ctx.src.categories.items.get(service.categoryId).name;
        //   //
      // }
      else this.vm.item.serviceCategoryId = undefined;
      this.getPrice();
    },
    "vm.item.service.id": function (val) {
      const item = ctx.src.services.get(val);
      this.vm.item.serviceCategoryId = item.categoryId;
      this.getPrice();
    },
    "vm.item.amount": function (val) {
      if (!val) return (this.vm.item.discountedAmount = 0);
      if (!this.vm.item.discountPercent || this.vm.item.discountPercent === 0)
        return (this.vm.item.discountedAmount = val);
      else
        return (this.vm.item.discountedAmount = round2hunds(
          val - (val * this.vm.item.discountPercent) / 100
        ));
    },
    "vm.item.discountPercent": function (val) {
      if (!val) return (this.vm.item.discountedAmount = this.vm.item.amount);
      else
        return (this.vm.item.discountedAmount = round2hunds(
          this.vm.item.amount - (this.vm.item.amount * val) / 100
        ));
    },
    "vm.item.organizationId": function (val) {
      this.fetchItems(val);
      this.getPrice();
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    async getPrice() {
      if (!this.vm.item.serviceId) return;
      if (!this.vm.item.organizationId) return;
      if (!this.vm.item.insuredId) return;
      const insured = fetch.get(`/api/insurance/insured/get?id=${this.vm.item.insuredId}`);
      if (!insured) return;
      const contract = fetch.get(`/api/insurance/contracts/${insured.contractId}`)
      if (!contract) return;
      const price = ctx.src.prices.find(
        this.vm.item.serviceId,
        this.vm.item.organizationId,
        contract.underwriterId
      );
      if (!price) return;
      this.vm.item.amount = price.amount;
    },
    async fetchItems(organizationId) {
      this.items = [];
      if (!organizationId) return;
      this.items = await fetch.get(
        "/api/accounting/invoices/get-list-by-organization?organizationId=" +
        organizationId
      );
    },

    async getServiceItems() {
      this.serviceItems = await fetch.get(`/api/accounting/services/get-active-services?insuredId=` + this.vm.item.insuredId);

      if (!this.vm.item.serviceId) return;
      if (
        this.serviceItems.filter(s => s.id === this.vm.item.serviceId)
          .length === 0
      ) {
        this.serviceItems.push(ctx.src.services.get(this.vm.item.serviceId));
      }
    },
    zeroQuantityRule(v) {

      return v > 0 || (this.isReserve && !!this.vm.item && zeroQuantityInvoiceServices.includes(this.vm.item.serviceId)) || 'Количество должно быть больше нуля'
    }
  },

  async mounted() {
    this.loading = true;
    this.vm.serviceDetail = this;
    this.validate();
    await this.fetchItems(this.vm.item.organizationId);
    await this.getServiceItems();
    this.loading = false;
  }
};
</script>
