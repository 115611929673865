<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <FutureServicesDialog :hasFutureServices="hasFutureServices"></FutureServicesDialog>
    <v-container fluid>
      <v-subheader>
        Личные данные
      </v-subheader>
      <v-divider></v-divider>
      <v-row aling="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="isVerifiedLabel"
            label="Данные прошли проверку в ЕСБД"
            :readonly="true"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="person_item.person.lastName"
            label="Фамилия"
            :rules="[requiredRules]"
            :readonly="!isNewEntry && personIsVerified"
            required
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="person_item.person.firstName"
            label="Имя"
            :rules="[requiredRules]"
            :readonly="!isNewEntry && personIsVerified"
            required
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="person_item.person.middleName"
            :readonly="!isNewEntry && personIsVerified"
            label="Отчество"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="4" sm="4">
          <v-checkbox v-model="person_item.person.isResident" class="d-flex" cols="12" sm="4" label="Резидент">
          </v-checkbox>
        </v-col>
        <v-col v-if="!person_item.person.isResident" class="d-flex" cols="4" sm="4">
          <v-text-field
            v-model="person_item.person.passportNo"
            label="Номер паспорта"
            :rules="[requiredRules]"
            :readonly="!isNewEntry && personIsVerified"
            required
          ></v-text-field>
        </v-col>
        <v-col v-else class="d-flex" cols="4" sm="4">
          <v-text-field
            v-model="person_item.person.iin"
            label="ИИН"
            :rules="[iinRule]"
            :counter="12"
            :readonly="!isNewEntry && personIsVerified"
            required
            type="number"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="person_item.person.birthDate"
            label="Дата рождения"
            :readonly="!isNewEntry && personIsVerified"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-autocomplete
            v-model="person_item.person.sexKey"
            :items="ctx.src.dict['INS_SEX'].items"
            :label="'Пол'"
            :readonly="!isNewEntry && personIsVerified"
            item-text="value"
            item-value="key"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.person.phoneMobile1"
            :rules="[phoneNumberRule]"
            label="Мобильный телефон 1"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.person.phoneMobile2"
            :rules="[phoneNumberRule]"
            label="Мобильный телефон 2"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.person.phoneHome"
            label="Домашний телефон"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.person.phoneWork"
            label="Рабочий телефон"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="person_item.person.cityKey"
            :items="ctx.src.dict['INS_CITY'].items"
            :label="'Город'"
            item-text="value"
            item-value="key"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="9">
          <v-text-field
            v-model="person_item.person.address"
            label="Адрес"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-subheader>
        Данные страховки
      </v-subheader>
      <v-divider></v-divider>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="4">
          <ContractsSelect :props="{
            id: isNewEntry ? null : item.contractId,
            byContract: byContract
          }"></ContractsSelect>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-autocomplete
            v-model="person_item.programId"
            :items="programs"
            :label="'Программа'"
            item-text="name"
            item-value="id"
            :rules="[requiredRules]"
            required>
            <template v-slot:item="data">
              <template v-if="!data.item.id">
                <v-list-item-content
                  v-text="'Загрузка...'"
                ></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row dense>
                      <v-col cols="12" sm="6">
                        <h6>Страховая сумма</h6>
                        <h4>{{ data.item.amount }}</h4>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <h6>Страховая премия</h6>
                        <h4>{{ data.item.premium }}</h4>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="person_item.cardNo"
            label="Номер карты"
            :rules="[requiredRules]"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-autocomplete
            v-model="person_item.parentId"
            :items="insuredParents"
            :label="'Прикреплен к'"
            item-text="person.fullName"
            item-value="id"
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            label="Тип"
            readonly
            :value="relationTypeText"
          >
          </v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.individualContractNo"
            label="Инд. № договора"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="organization"
            label="Компания"

            readonly
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="underwriter"
            label="Страховая компания"
            readonly
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.startDate"
            label="Дата с"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.endDate"
            label="Дата по"
            type="date"
            :readonly="!isNewEntry && personIsVerified"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.amount"
            label="Сумма"
            type="number"
            :disabled="disabledByParent"
            :readonly="!isNewEntry && personIsVerified"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="person_item.premium"
            label="Премия"
            type="number"
            :readonly="!isNewEntry && personIsVerified"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import dateIsoFilter from "../../filters/dateIso.filter";
import FutureServicesDialog from "@/components/insured/FutureServicesDialog";
import moment from "moment";
import ContractsSelect from "@/components/contracts/ContractsSelect";

let Item = function (d) {
  let self = this;

  d.person = d.personId;
  self.person2vm = function (val) {
    return ctx.src.persons.get(val);
  };

  js2vm(self, d);
};

export default {
  components: {ContractsSelect, FutureServicesDialog},

  props: {
    item: Object,
    vm: Object,
    byContract: {type: Boolean, default: false},
    contract: {

    }
  },

  data() {
    return {
      hasFutureServices: false,
      ctx,
      requiredRules: v => !!v || "Обязательное поле",
      iinRule: v => v.length === 12 || "ИИН должен содержать 12 цифр",
      phoneNumberRule: v => /\d{11}/.test(v) && v.length === 11 || !v || "Номер телефона должен состоять из 11 цифр без лишних символов",
      valid: false,
      insuredByContract: [],
      programs: [],
      contracts: [],
      person_item: this.item,
      is_resident: null,
    }
  },

  async mounted() {
    this.loading = true;
    await this.init();
    this.loading = false;

    console.log('this.person_item', this.person_item);

    if (this.$route.query.cardNo) {
      await this.isHasCardNo();
      this.vm.item = await this.person_item;


      //  TODO
      // this.is_resident = this.item.person?.isResident;

      // тестирование
      // this.vm.item = await {

      //   "cardStatus": "RELEVANT",
      //   "id": 21706139,
      //   "deleted": false,
      //   "personId": 21706134,
      //   "contractId": 21706004,
      //   "programId": 21706006,
      //   "underwriterId": 4190802,
      //   "organizationId": 20477998,
      //   "cardNo": "CIS3465026",
      //   "startDate": "2022-11-23",
      //   "endDate": "2023-10-31",
      //   "premium": 21500,
      //   "amount": 500000,
      //   "parent": {
      //       "fullName": null
      //   },

      //   // "underwriter": {
      //   //     "id": 4190802,
      //   //     "fullName": "Сентрас Иншуранс",
      //   //     "typeKey": "1",
      //   //     "cityKey": "1000447",
      //   //     "deleted": false,
      //   //     "awaiting": false
      //   // },

      //   // "organization": {
      //   //     "id": 20477998,
      //   //     "fullName": "Нуржанов Арлан Женисович",
      //   //     "typeKey": "2",
      //   //     "cityKey": "1000447",
      //   //     "deleted": false,
      //   //     "awaiting": false
      //   // },

      //   "program": {
      //       "name": "Стандарт",
      //       "id": 21706006,
      //       "awaiting": false,
      //       "deleted": false,
      //       "amount": 500000,
      //       "premium": 21500
      //   },

      //   "person": {
      //       "awaiting": false,
      //       "id": 21706134,
      //       "fullName": "Нуржан Арлан Женисович",
      //       "deleted": false,
      //       "firstName": "Арлан",
      //       "lastName": "Нуржан",
      //       "middleName": "Женисович",
      //       "birthDate": "1992-12-16",
      //       "iin": "921216300396",
      //       "sexKey": "1",
      //       "cityKey": "1000447",
      //       "address": "ул. Абдирова 31, кв. 4",
      //       "phoneMobile1": "87057652141",
      //       "typeKey": "2",
      //       "isResident": true
      //   }
      // }

    }

    await this.init();
    this.loading = false;

  },

  // beforeCreate() {
  //   if(!this.person_item.id) {
  //     this.person_item.person.isResident = true;
  //   }
  // },

  watch: {
    item: function (val) {
      this.$refs.form.validate();
      this.person_item = val;
    },

    "item.endDate": async function (val) {
      if (moment(val) >= moment()) return
      this.hasFutureServices = await fetch.get("/api/insurance/insured/has-future-services?id=" + this.item.id)


    },
    "item.person.isResident": function (val) {
      this.is_resident = val;
    },
    "item.contractId": function (val) {
      if (!val) {
        this.person_item.programId = undefined;
        this.person_item.parentId = undefined;
        this.insuredByContract = [];
      } else {
        this.fetchProgramsByContract();
      }
    },
    "item.programId": function (val) {
      if (!val) {
        return;
      }
      if (val && !this.person_item.parentId) {
        const program = ctx.src.programs.get(val);
        if (!program) {
          return;
        }
        if (!this.person_item.amount) {
          this.person_item.amount = program.amount;
        }
        if (!this.person_item.premium) {
          this.person_item.premium = program.premium;
        }
      }
    },
    contracts(value) {
      console.log('contracts', value);
    }
  },
  computed: {

    relationTypeText() {
      return !this.person_item.parentId ? "Основной" : "Член семьи"
    },

    isNewEntry() {
      return !this.item.id
    },
    isVerifiedLabel() {
      return this.person_item.person?.verified ? "Да" : "Нет";
    },



    organization: function () {
      if (!this.contract) {
        return undefined;
      } else {
        return ctx.src.org.first(this.contract.organizationId)?.fullName;
      }
    },

    underwriter: function () {
      if (!this.contract) {
        return undefined;
      } else {
        return ctx.src.org.first(this.contract.underwriterId)?.fullName;
      }
    },
    personIsVerified() {
      return this.person_item.person?.verified;
    },

    insuredParents: function () {
      if (this.insuredByContract.length === 0 || this.insuredByContract[0].contractId !== this.person_item.contractId) {
        this.fetchInsuredByContract(this.person_item.contractId);
      }
      if (this.insuredByContract.length === 0) {
        return [];
      } else {
        return this.insuredByContract.filter(i => {
          return i.id !== this.person_item.id && !i.parentId;
        });
      }
    },

    disabledByParent: function () {
      if (this.person_item.parentId) {
        return true;
      } else {
        return false;
      }
    },

    typeIsNotObject: function (data) {
      return typeof data.item !== "object";
    },

    isNewRecord() {
      return Object.keys(this.person_item.person).length === 0;
    }

  },
  methods: {
    async setContract () {
      const contract = await fetch.get(`/api/insurance/contracts/${this.person_item.contractId}`);

      console.log('CONTRACT', contract);

      if (!this.person_item.contractId) {
        return;
      }
      if (!this.person_item.startDate) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.person_item.startDate = dateIsoFilter(contract.startDate);
      }
      if (!this.person_item.endDate) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.person_item.endDate = dateIsoFilter(contract.startDate);
      }

      this.contract = contract;
    },
    validate() {
      this.$refs.form.validate();
    },

    async fetchInsuredByContract(contractId) {
      this.insuredByContract = [];
      if (!contractId) return;
      let res = await fetch.get(
        "/api/insurance/insured/get-by-contract?contractId=" + contractId
      );
      if (res) {
        res.forEach(element => {
          this.insuredByContract.push(new Item(element));
        });
      }
    },

    async fetchProgramsByContract(contractId) {
      this.programs = await fetch.get(
        "/api/insurance/programs/get-by-contract?contractId=" + (this.person_item.contractId || contractId)
      );
      return this.programs;
    },



    async init() {
      this.vm.insuredDetail = this;
      if (this.person_item.contractId) {
        await this.fetchProgramsByContract();
      }
      this.is_resident = !!this.person_item.person && this.person_item.person.isResident || !this.person_item.person
      await this.setContract()
    },

    /**
     * Вызываем этот метод если в параметрах появиться cardNo застрахованного (начало)
     * костыль из за невозможности инициализировать
     * TODO:
     */
    async isHasCardNo() {
      const personCardNo = await fetch.get(`/api/src/insured/get-by-card-no?cardNo=${this.$route.query.cardNo}`);

      const [insuredResponse, programsResponse, person] = await Promise.all([
        fetch.get("/api/insurance/insured/get-by-contract?contractId=" + personCardNo.contractId),
        fetch.get("/api/insurance/programs/get-by-contract?contractId=" + personCardNo.contractId),
        fetch.get("/api/persons/get?id=" + personCardNo.personId)
      ]);

      if (insuredResponse) {
        insuredResponse.forEach(element => {
          if (element.cardNo === this.$route.query.cardNo) {
            const program = programsResponse.find(p => p.id === personCardNo.programId);
            this.person_item = {
              // "cardStatus": "RELEVANT",
              "id": element.id,
              "deleted": element.deleted,
              "personId": element.personId,
              "contractId": element.contractId,
              "programId": program.id,
              "underwriterId": element.underwriterId,
              "organizationId": element.organizationId,
              "cardNo": element.cardNo,
              "startDate": dateIsoFilter(element.startDate),
              "endDate": dateIsoFilter(element.endDate),
              "premium": element.premium,
              "amount": element.amount,
              "person": {
                ...person,
                "birthDate": dateIsoFilter(person.birthDate),
                "isResident": person.isResident
              },
              "program": {
                "name": program.name,
                "id": program.id,
                "deleted": program.deleted,
                "amount": program.amount,
                "premium": program.premium
              },
            };

            this.is_resident = person.isResident;

          }
        });
      }
    },

    /**
     * Вызываем этот метод если в параметрах появиться cardNo застрахованного (конец)
     */
  },
};
</script>
