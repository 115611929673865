<template>
  <div class="view-wrapper">
    <div class="view">
      <v-card>
        <v-card-title style="padding: 4px 16px">
          <h6>Фильтр</h6>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.from"
                  label="С"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.to"
                  label="По"
                  type="date"
                  required
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="filter.byVisitDate"
                  :label="'По дате обращения'"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="filter.dontShowReserves"
                  :label="'Не показывать резервы'"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="2">
                <v-radio-group v-model="filter.companyPrefix">
                  <v-radio
                    label="Все услуги"
                    value="ALL"
                  >

                  </v-radio>
                  <v-radio
                    label="Услуги СОСМА"
                    value="SOSMA"
                  >

                  </v-radio>
                  <v-radio
                    label="Сторонние Клиники"
                    value="OTHERS"
                  >

                  </v-radio>

                </v-radio-group>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.clientOrganizationId"
                  :items="ctx.src.org.byType('2')"
                  :label="'Клиентская'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.underwriterId"
                  :items="ctx.src.org.byType('1')"
                  :label="'Страховая'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.organizationId"
                  :items="ctx.src.org.byType(['3', '4'])"
                  :label="'Мед. учреждение'"
                  item-text="fullName"
                  item-value="id"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <ContractsSelect :props="{
                  id: null,
                  byContract: false,

                }" v-on:contractSelected="updateContractFilter"></ContractsSelect>
              </v-col>

              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.serviceCategoryId"
                  :items="ctx.src.categories.items"
                  :label="'Категория услуги'"
                  item-text="name"
                  item-value="id"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.serviceId"
                  :items="ctx.src.services.list()"
                  :label="'Услуга'"
                  item-text="name"
                  item-value="id"
                  clearable
                  required
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.cardNo"
                  label="Номер карты"
                  clearable
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.personName"
                  label="ФИО"
                  clearable
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.sexKey"
                  :items="ctx.src.dict['INS_SEX'].items"
                  :label="'Пол'"
                  item-text="value"
                  item-value="key"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.birthYear"
                  label="Год рождения"
                  clearable
                  type="number"
                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.city"
                  :items="ctx.src.dict['INS_CITY'].items"
                  :label="'Город'"
                  item-text="value"
                  item-value="value"
                  clearable
                  @keydown.enter="fetchItems"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            :loading="loading"
            :disabled="exporting"
            @click="apply"
          >
            Применить
          </v-btn>
          <v-btn
            small
            :loading="loading"
            :disabled="exporting"
            @click="resetFilter"
          >
            Сбросить
          </v-btn>
          <v-spacer/>
          <v-btn
            color="green"
            :loading="exporting"
            :disabled="items.length === 0"
            small
            class="white--text"
            @click="exp"
          >
            Экспорт
            <v-icon right dark>
              cloud_download
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-simple-table fixed-header dense style="height: 100%">
        <template v-slot:default>
          <thead>
          <tr>
            <th>№</th>
            <th>Номер карты</th>
            <th>ФИО</th>
            <th>Прикреплен к</th>
            <th>
              Пол
            </th>
            <th>
              Возраст
            </th>
            <th>
              Телефон
            </th>
            <th>
              Клиентская
            </th>
            <th>
              Доктор
            </th>
            <th>
              Программа
            </th>
            <th>
              Страховая
            </th>
            <th>
              Дата введения
            </th>
            <th>
              Дата обращения
            </th>
            <th>
              Поставщик
            </th>
            <th>
              Город
            </th>
            <th>
              Категория
            </th>
            <th>
              Услуга
            </th>
            <th>
              Диагноз
            </th>
            <th>
              Счет
            </th>
            <th>
              Сумма
            </th>
            <th>
              Скидка
            </th>
            <th>
              Сумма со скидкой
            </th>
            <th>
              Сумма total
            </th>
            <th>
              Нагрузка, %
            </th>
          </tr>
          </thead>
          <tbody v-for="(item, i) in items" :key="i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td class="text-left">
              {{ item.cardNo }}
            </td>
            <td class="text-left">
              {{ item.personName }}
            </td>
            <td class="text-left">
              {{ item.parentName }}
            </td>
            <td>{{ item.sex }}</td>
            <td>
              {{ item.age }}
            </td>
            <td>
              {{ item.phoneNumber }}
            </td>
            <td class="text-left">
              {{ item.clientOrganization }}
            </td>
            <td class="text-left">
              {{ item.doctorName }}
            </td>
            <td class="text-left">
              {{ item.program }}
            </td>
            <td class="text-left">
              {{ item.underwriter }}
            </td>
            <td>
              {{ item.postingDate }}
            </td>
            <td>
              {{ item.visitDate }}
            </td>
            <td class="text-left">
              {{ item.organization }}
            </td>
            <td class="text-left">
              {{ item.organizationCity }}
            </td>
            <td class="text-left">
              {{ item.serviceCategory }}
            </td>
            <td class="text-left">
              {{ item.service }}
            </td>
            <td class="text-left">
              {{ item.diagnosis }}
            </td>
            <td class="text-left">
              {{ item.invoiceNumber }}
            </td>
            <td class="text-right">
              {{ item.amount }}
            </td>
            <td class="text-right">
              {{ item.discountPercent }}
            </td>
            <td class="text-right">
              {{ item.discountedAmount }}
            </td>
            <td class="text-right">
              {{ item.insuranceAmount }}
            </td>
            <td class="text-right">
              {{ item.increase }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import ctx from "@/ctx";
import fetch from "@/utils/fetch";
import js2vm from "@/utils/js2vm";
import dateIsoFilter from "@/filters/dateIso.filter";
import vm2js from "@/utils/vm2js";
import ContractsSelect from "@/components/contracts/ContractsSelect";

let Filter = function () {
  let self = this;

  let from = new Date();
  from.setDate(from.getDate() - 30);
  self.from = dateIsoFilter(from);
  self.to = dateIsoFilter(new Date());
  self.byVisitDate = false;
  self.dontShowReserves = false;

  self.clientOrganizationId = undefined;
  self.underwriterId = undefined;
  self.organizationId = undefined;
  self.contractId = undefined;
  self.serviceCategoryId = undefined;
  self.serviceId = undefined;

  self.cardNo = undefined;
  self.personName = undefined;
  self.sexKey = undefined;
  self.birthYear = undefined;
  self.insuranceAmount = undefined;
  self.companyPrefix = "ALL"
};

let Item = function (d) {
  let self = this;

  js2vm(self, d);
  self.postingDate = dateIsoFilter(self.postingDate);
  self.visitDate = dateIsoFilter(self.visitDate);
};

export default {
  components: {ContractsSelect},
  data: () => ({
    ctx,
    currentPage: 0,
    totalPages: 0,
    loading: false,
    report: {},
    filter: new Filter(),
    items: [],
    exporting: false,
    filter_: undefined
  }),
  methods: {
    resetFilter() {
      this.filter = new Filter();
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    async exp() {
      this.exporting = true;
      const res = await fetch.post(
        "/api/report/by-company/export",
        this.filter_
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.exporting = false;
    },
    apply() {
      this.items = [];
      this.currentPage = 0;
      this.filter_ = vm2js(this.filter);
      this.fetchItems({page: 0});
    },
    async fetchItems(params) {
      this.loading = true;
      let res = await fetch.post(
        "/api/report/by-company/get?page=" + params.page,
        this.filter_
      );
      if (res) {
        if (res.first) {
          this.totalPages = res.totalPages;
        }
        res.content.forEach(r => {
          this.items.push(new Item(r));
        });
      }
      this.loading = false;
    },
    updateContractFilter(contract) {
      this.filter.contractId = contract.id
    }

  },
  computed: {
    getCtxItemsFiltered() {
      return this.ctx.src.contracts.items.filter(
        item => item.deleted === false
      );
    }
  },
  mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
  }
};
</script>
