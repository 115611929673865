<template>
  <div class="view" style="max-height: calc(100vh - 64px)">
    <v-toolbar class="my-bar">
      <SaveButton
        :additional-rules="!valid"
        :handler="save"
      ></SaveButton>
      <v-btn icon @click="addItem">
        <v-icon>add</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="my-content">
      <v-form ref="form" v-model="valid" style="height: 100%">
        <v-simple-table fixed-header dense style="height: 100%">
          <template v-slot:default>
            <thead>
            <tr>
              <th></th>
              <th>№</th>
              <th class="text-left" style="min-width: 20vw">
                Застрахованный
              </th>
              <th class="text-left">
                Доктор
              </th>
              <th class="text-left">
                Дата введения
              </th>
              <th class="text-left">
                Дата обращения
              </th>
              <th class="text-left" style="min-width: 12vw">
                Диагноз
              </th>
              <th class="text-left" style="min-width: 12vw">
                Услуга
              </th>
              <th class="text-left" style="min-width: 8vw">
                Категория
              </th>
              <th class="text-left">
                Кол-во
              </th>
              <th class="text-left">
                Сумма
              </th>
              <th class="text-left">
                % Скидка
              </th>
              <th class="text-left">
                Со скидкой
              </th>
              <th class="text-left">
                Контрольный номер
              </th>
            </tr>
            </thead>
            <tbody v-for="(item, i) in items" :key="i">
            <ServiceAddItem :item="item" :index="i" :items="items" v-on:validate="validate"
                            v-on:invoiceServiceUniquenessParametersChange="handleInvoiceServiceUniquenessParametersChange"></ServiceAddItem>
            </tbody>
          </template>
        </v-simple-table>
      </v-form>
    </div>
    <v-dialog v-model="nonUniqueInvoiceServiceDialogShown">
      <v-card>
        <v-toolbar class="my-bar">
          <v-btn icon @click="closeInvoiceServiceUniquenessDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>

          <v-alert
            color="red"
            type="info"
            style="margin-top: 16px"
          >
            ВНИМАНИЕ! Невозможно сохранить запись, услуга внесена ранее!
          </v-alert>
        </v-card-text>
      </v-card>


    </v-dialog>
    <CheckLimit :vm="this"></CheckLimit>
  </div>
</template>

<script>
import ctx from "@/ctx";
import ServiceAddItem from "./ServiceAddItem";
import dateIsoFilter from "../../filters/dateIso.filter";
import vm2js from "../../utils/vm2js";
import fetch from "../../utils/fetch";
import CheckLimit from "../balance/Check";
import {v4 as uuidv4} from 'uuid';
import js2vm from "@/utils/js2vm";
import SaveButton from "@/components/ButtonFacade";

const defaultValues = {
  serviceCategoryId: undefined,
  serviceId: undefined,
  productId: undefined,
  insuredId: undefined,
  diagnosisId: undefined,
  doctorId: undefined,
  stateKey: "E",
  quantity: 1,
  amount: 1000,
  discountPercent: 0,

}

const requestObjectToItem = (requestObject, item) => {

  for (const [key, value] of Object.entries(defaultValues)) {
    item[key] = requestObject && requestObject[key] ? requestObject[key] : value;
  }

}
let Item = function (d, invoiceId, organizationId) {
  let self = this;


  self.invoiceId = invoiceId;
  self.organizationId = organizationId;

  requestObjectToItem(d, self);




  self.discountedAmount = self.amount;

  self.checkNumber = d ? d.checkNumber : undefined;


  if (d) {
    js2vm(self, d)
    self.insuredId = d.insuredId
  }

  self.postingDate = d ? dateIsoFilter(d.postingDate) : dateIsoFilter(new Date());
  self.visitDate = d ? dateIsoFilter(d.visitDate) : dateIsoFilter(new Date());

  self.id = undefined;


};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    items: [],
    valid: false,
    timerId: undefined,
    mount: true,
    nonUniqueInvoiceServiceDialogShown: false
  }),
  methods: {
    async getTemp() {
      const tempData = await fetch.get("/api/accounting/invoices/get-invoice-service-temp?invoiceId=" + this.vm.vm.item.id);


      tempData.forEach(element => this.items.unshift(new Item(element, element.invoiceId, element.organizationId)))



      let freshItem = new Item(undefined, this.vm.vm.item.id, this.vm.vm.item.organizationId);
      freshItem.entryUuid = uuidv4();
      this.items.push(freshItem);

    },
    performAutosave() {

      this.timerId = setInterval(() => {
        if (this.items.length === 0) return;

        fetch.post('/api/accounting/invoices/save-invoice-service-temp', vm2js(this.items));
      }, 5000)

    },
    checkLimit: async function (item) {

      if (this.mount) return;
      this.limitCheck.setItem(item);
      await this.limitCheck.check(
        item.insuredId,
        item.serviceCategoryId,
        item.discountedAmount,
        item.quantity,
      );
    }
    ,
    addItem() {
      let item = undefined;

      if (this.items.length === 0 || this.items[this.items.length - 1].checkNumber) {
        item = new Item(vm2js(this.items[this.items.length - 1]));
        item.checkNumber = '';

      } else {
        item = new Item(vm2js(this.items[this.items.length - 1]));

      }
      item.entryUuid = uuidv4();
      if(this.organizationIsSosmaRegions) {
        item.insuredId = null;
        item.serviceId = null;
      }

      this.items.push(
        item
      )
    },

    validate() {
      this.$refs.form.validate();
    },
    save: async function () {
      if (!this.valid) return;
      clearInterval(this.timerId);

      const res = await fetch.post(
        "/api/accounting/invoices/save-service-list",
        vm2js(this.items)
      );
      if (res) {
        this.vm.fetchItems();
        this.vm.dialog = false;
      }
    },
    handleInvoiceServiceUniquenessParametersChange(doesInvoiceServiceExist) {

      if(doesInvoiceServiceExist) {
        this.valid = false;
        this.nonUniqueInvoiceServiceDialogShown = true;
      }
      else {
        this.valid = true;
        this.validate()
      }
    },
    closeInvoiceServiceUniquenessDialog() {
      this.nonUniqueInvoiceServiceDialogShown = false;
    }
  },
  computed: {
    organizationIsSosmaRegions() {
      return this.vm.vm.item.organizationId=== 10499899;
    }
  },

  async mounted() {


    this.performAutosave();

    await this.getTemp();


    this.validate();
    this.mount = false;
  },
  beforeDestroy() {
    clearInterval(this.timerId);
    this.items = [];
  },
  components: {
    SaveButton,
    ServiceAddItem,
    CheckLimit
  }
};
</script>
//
