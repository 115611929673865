<template>
    <div class="view-wrapper">
      <div class="view">
        <v-card>
          <v-card-title style="padding: 4px 16px">
            <h6>Фильтр</h6>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="filter.from"
                    label="С"
                    type="date"
                    required
                    @keydown.enter="fetchItems"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="filter.to"
                    label="По"
                    type="date"
                    required
                    @keydown.enter="fetchItems"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-select
                    v-model="filter.typeKey"
                    :items="dropdownItems"
                    label="Выберите значение"
                    required
                    @change="fetchItems">
                </v-select>
            </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" small @click="fetchItems">
              Применить
            </v-btn>
            <v-btn small @click="resetFilter">
              Сбросить
            </v-btn>
            <v-spacer />
            <v-btn
              color="green"
              :loading="exporting"
              :disabled="items.length === 0"
              small
              class="white--text"
              @click="exp"
            >
              Экспорт
              <v-icon right dark>
                cloud_download
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          :single-select="true"
          item-key="id"
          hide-default-footer
          :loading="loading"
          loading-text="Загрузка... Пожалуйста подождите"
          fixed-header
        >
          <template v-slot:item.visitDateLocal="{ item }">
            <span>{{ item.visitDateLocal | dateLocalFilter }}</span>
          </template>
          <template v-slot:item.postingDateLocal="{ item }">
            <span>{{ item.postingDateLocal | dateLocalFilter }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </template>
  
  <script>
  import ctx from "@/ctx";
  import fetch from "@/utils/fetch";
  import js2vm from "@/utils/js2vm";
  import dateIsoFilter from "@/filters/dateIso.filter";
  import vm2js from "@/utils/vm2js";
  import dictFilter from "@/filters/dict.filter";
  
  let Filter = function() {
    let self = this;
  
    let from = new Date();
    from.setDate(from.getDate() - 30);
    self.from = dateIsoFilter(from);
    self.to = dateIsoFilter(new Date());
    self.dropdownItems = null;
  };
  
  let Item = function(d) {
    let self = this;
  
    js2vm(self, d);
  };
  
  let Report = function(d) {
    let self = this;
  
    d.rows.forEach(i => {
      self.rows.push(new Item(i));
    });
  };
  
  export default {
    data: () => ({
      ctx,
      selected: [],
      loading: false,
      headers: [
        {
          text: "Мед. Учреждение",
          align: "start",
          sortable: false,
          value: "organization"
        },
        {
          text: "Доктор",
          sortable: false,
          value: "doctor"
        },
        {
          text: "Тип",
          sortable: false,
          value: "typeKey"
        },
        {
          text: "Причина",
          sortable: false,
          value: "reason"
        },
        {
          text: "Диагноз",
          sortable: false,
          value: "diagnosis"
        },
        {
          text: "Примечание",
          sortable: false,
          value: "comment"
        },
        {
          text: "Автор",
          sortable: false,  
          value: "creatorName"
        }
      ],
      report: {},
      filter: new Filter(),
      items: [],
      dropdownItems: [],
      exporting: false
    }),
    methods: {
      resetFilter() {
        this.filter = new Filter();
      },
      async exp() {
        this.exporting = true;
        const res = await fetch.post(
          "/api/report/by-visit/export",
          vm2js(this.filter)
        );
        if (res) {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(await res.blob);
          link.download = res.filename;
          link.click();
        }
        this.exporting = false;
      },
      async fetchItems() {
        this.loading = true;
        this.items = [];
        let res = await fetch.post(
          "/api/report/by-visit/get-list",
          vm2js(this.filter)
        );
        if (res) {
          res.forEach(r => {
            console.log(r)
            this.items.push(new Item(r));
          });
        }
        this.loading = false;
      },
      async fetchDropdownItems() {
    try {
      const response = await fetch.get("/api/dict/get-list-by-key?key=INS_VISIT_TYPE");
      if (response) {
        this.dropdownItems = response.map(item => ({
          text: item.value,
          value: item.key 
        }));
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных для dropdown:", error);
    }
  }
    },
    mounted() {
  this.fetchDropdownItems();
}
  };
  
  </script>
  