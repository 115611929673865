<template>
  <v-form ref="form" v-model="valid" lazy-validation style="overflow: auto">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.statusKey"
            :items="ctx.src.dict['INS_VISIT_STATUS'].items"
            :label="'Статус'"
            item-text="value"
            item-value="key"
            required
            :rules="requiredRules"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.typeKey"
            :items="ctx.src.dict['INS_VISIT_TYPE'].items"
            :label="'Тип'"
            item-text="value"
            item-value="key"
            required
            :rules="requiredRules"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.visitDate"
            label="Дата создания"
            type="date"
            required
            :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.visitTime"
            label="Время создания"
            type="time"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="vm.item.organizationId"
            :items="ctx.src.org.byType('3')"
            :label="'Поставщик'"
            item-text="fullName"
            item-value="id"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          <v-autocomplete
            v-model="vm.item.doctorId"
            :items="ctx.src.doctors.items"
            :label="'Доктор'"
            item-text="fullName"
            item-value="id"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.actionDate"
            label="Дата актива"
            type="date"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="vm.item.actionTime"
            label="Время актива"
            type="time"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col v-if="!byInsured" cols="12" md="12">
          <v-autocomplete
            v-model="vm.item.insuredId"
            :items="ctx.src.insured.items"
            :label="'Застрахованный'"
            item-text="label"
            item-value="id"
            required
            :rules="requiredRules"
            clearable
          >
          </v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="vm.item.cardNumber"
            label="Номер карты"
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.fullName"
            label="ФИО"
            :readonly="true"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="8">
          <v-text-field
            v-model="vm.item.contacts"
            label="Контакты"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field v-model="vm.item.age"
           label="Возраст"
            :readonly="true">
          </v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field v-model="vm.item.address"
           label="Адрес">
          </v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12" >
          <v-textarea v-model="vm.item.reason"
           label="Причина"
            rows="1"
            auto-grow>
          </v-textarea>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-text-field
            v-model="vm.item.diagnosis"
            label="Диагноз"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-textarea
            v-model="vm.item.comment"
            label="Примечание"
            rows="1"
            auto-grow
          ></v-textarea>
        </v-col>
        <v-col class="d-flex" cols="12" sm="12">
          <v-textarea
            v-model="vm.item.creatorName"
            label="Автор"
            rows="1"
            :readonly="true"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
  import ctx from "@/ctx";
  import fetch from "@/utils/fetch";
  import jwtParser from "@/utils/jwtParser";
  import storage from "@/utils/storage";


  export default {
    name: "Contract",
    props: {
      vm: Object,
      byInsured: { type: Boolean, default: false }
    },
    data: () => ({
      ctx,
      valid: false,
      requiredRules: [v => !!v || "Обязательное поле"],
    }),
    methods: {
      validate() {
        this.$refs.form.validate();
      },
      async fillPersonalData(insuredId){
        const res = await fetch.get(
          "/api/persons//get-data-by-insId?insId=" + insuredId);
        if(res){
          this.vm.item.age = res.age
          this.vm.item.fullName = res.fullName
          if(!this.vm.item.address){
            this.vm.item.address = res.address
          }
          if(!this.vm.item.contacts){
            this.vm.item.contacts = res.phone
          }
        }
      },
      async getUserName(userId){
        const res = await fetch.get(
          "/api/users/get-name-by-id?id=" + userId);
        if(res){
          this.vm.item.creatorName = res
        }
      },
      async getCardNumber(insuredId){
        const res = await fetch.get(
          "/api/insurance/insured/get-card-number?id=" + insuredId);
          console.log(res)
        if(res){
          this.vm.item.cardNumber = res
        }
      },
      updateFields() {
      this.$set(this.vm.item, "age", this.vm.item.age || "");
      this.$set(this.vm.item, "address", this.vm.item.address || "");
      this.$set(this.vm.item, "contacts", this.vm.item.contacts || "");
      this.$set(this.vm.item, "fullName", this.vm.item.fullName || "");
      this.$set(this.vm.item, "cardNumber", this.vm.item.cardNumber || "");
      this.$set(this.vm.item, "creatorName", this.getUserName(jwtParser.parse(storage.getJwt()).userId) || "");
      }
    },
      mounted() {
        this.vm.detail = this;
        this.validate();
        this.fillPersonalData(this.vm.item.insuredId);
        this.getCardNumber(this.vm.item.insuredId)
        this.updateFields();
      }
  };
</script>
